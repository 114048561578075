<template>
  <div>
    <my-table :rows="rows" :columns="columns" :isLoading="isLoading">
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'id'">
          <b-button
            variant="gradient-primary"
            block
            @click="goToOrderSummary(props.row.id)"
          >
            {{ props.row.id }}
          </b-button>
        </span>
        <div
          v-if="props.column.field === 'order_type'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ props.row.category_type }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'delivery_type'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ props.row.shipment_delivery_type }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'shipment_type'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ props.row.fleet_shipment_type }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'pickup'"
          class="d-flex justify-content-center"
        >
          <b>
            {{
              props.row.shipment_delivery_type === 'Door to Door'
                ? props.row.sender.complete
                : props.row.pickup_port.complete
            }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'destination'"
          class="d-flex justify-content-center"
        >
          <b>
            {{
              props.row.shipment_delivery_type === 'Door to Door'
                ? props.row.recipient.complete
                : props.row.dropoff_port.complete
            }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'fleetId'"
          class="d-flex justify-content-center"
        >
          {{ props.row.quote ? props.row.quote.id : 'N/A' }}
        </div>
        <div
          v-if="props.column.field === 'pickup_date'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ $helpers.formatDate(props.row.pickup_date) }}
          </b>
        </div>
        <div
          v-if="props.column.field === 'delivery_date'"
          class="d-flex justify-content-center"
        >
          <b>
            {{ $helpers.formatDate(props.row.delivery_date) }}
          </b>
        </div>

        <span v-if="props.column.field === 'status'">
          <b-badge class="p-1 w-100" :variant="props.row.status.color_name">
            {{ props.row.status.text }}
          </b-badge>
        </span>

        <div
          v-if="props.column.field === 'quotation'"
          class="d-flex justify-content-center"
        >
        {{
          props.row.quote
            ? $helpers.formatTotal(props.row.total_price)
            : 'RM 0.00'
        }}
        </div>
        <div
          v-if="props.column.field === 'document'"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="secondary"
            class="rounded"
            @click="openModallist(props.row.id)"
          >
            <feather-icon
              icon="InfoIcon"
              size="15"
              class="text-white align-middle mr-25"
            />
            View
          </b-button>
        </div>

        <div
          v-if="props.column.field === 'action'"
          class="d-flex justify-content-center"
        >
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="goToOrderSummary(props.row.id)">
              <span>View Details</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template></my-table
    >
    <!-- QUOTES LIST MODAL -->
    <b-modal
      size="lg"
      v-model="modalVisible"
      title="Quotes List"
      hide-footer
      centered
    >
      <div v-if="quotes">
        <ul>
          <li v-for="(quote, index) in quotes" :key="index">
            <b-row class="mb-2">
              <b-col cols="6"
                ><div>
                  <strong>Description:</strong> {{ quote.description }}<br />
                  <strong>ID:</strong> {{ quote.id }}<br />
                  <strong>Order ID:</strong> {{ quote.order_id }}<br />
                  <strong>Delivery ETA:</strong> {{ quote.delivery_date }}<br />
                  <strong>Total Amount:</strong> {{ quote.total }}
                </div></b-col
              >
              <b-col cols="7">
                <div class="d-flex">
                  <b-button
                    variant="secondary"
                    class="rounded mr-1"
                    @click="openQuoteModallist(index)"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="15"
                      class="text-white align-middle mr-25"
                    />
                    View Document
                  </b-button>
                  <b-button
                    class="mr-1"
                    variant="warning"
                    @click="rejectBid(quote)"
                    >Reject</b-button
                  >
                  <b-button variant="success" @click="acceptBid(quote)"
                    >Accept</b-button
                  >
                </div>
              </b-col>
            </b-row>
            <hr />
          </li>
        </ul>
      </div>
      <div v-else>
        <p>No quotes available.</p>
      </div>
    </b-modal>

    <!-- QUOTE AND DOCUMENT MODAL PREVIEW -->
    <b-modal
      ref="modal"
      size="lg"
      :title="'Customer Document'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        class="overflow-auto d-flex align-items-center justify-content-center"
      >
        <template>
          <div>
            <iframe
              v-if="isPDF(src)"
              :src="src"
              frameborder="3"
              width="700"
              height="750"
              :allowfullscreen="true"
            />
            <div v-else class="text-center">No preview available.</div>
          </div>
        </template>
      </div>
    </b-modal>

    <!-- QUOTE AND DOCUMENT MODAL LIST -->
    <b-modal
      ref="modallist"
      size="lg"
      :title="'Customer Document List'"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <div
        class="overflow-auto align-items-center justify-content-center"
      >
        <div>
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Quoted Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              class="d-flex justify-content-center flex-wrap"
              v-if="order.quote && order.quote.documents.length"
            >
              <div
                v-for="(document, index) in order.quote.documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  @click="openModal(document.path)"
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                >
                  <feather-icon icon="FileIcon" size="25" />
                  {{ document.name }}</b-button
                >
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Quoted Document </a>
              </div>
            </div>
          </div>
        </div>

        <div class="my-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Customer Documents </span>
            <hr class="flex-grow-1" />
          </div>
          <div class="text-center">
            <div
              class="d-flex justify-content-center flex-wrap"
              v-if="order.order_documents && order.order_documents.length"
            >
              <div
                v-for="(document, index) in order.order_documents"
                :key="index"
                class="d-flex my-1"
              >
                <b-button
                  @click="openModal(document.document)"
                  class="d-flex align-items-center btn-sm mr-1 ml-2"
                  variant="outline-primary"
                >
                  <feather-icon icon="FileIcon" size="25" />
                  {{ document.document_name }}</b-button
                >
                <b-button
                  class="btn-sm"
                  @click="deleteFile(index)"
                  variant="primary"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="15"
                    class="text-white align-middle"
                  />
                </b-button>
              </div>
            </div>
            <div v-else class="d-flex justify-content-center">
              <div>
                <a> No Customer Document </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-1">
            <b-button
              class="btn-sm"
              @click="triggerFileInput"
              variant="success"
            >
              <feather-icon
                icon="PlusIcon"
                size="15"
                class="text-white align-middle"
              />
              Add
            </b-button>
            <input
              type="file"
              ref="fileInput"
              style="
                opacity: 0;
                position: absolute;
                left: -9999px;
                top: -9999px;
              "
              @change="uploadFile"
            />
          </div>
        </div>

        <div class="mb-2">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Receipts </span>
            <hr class="flex-grow-1" />
          </div>
          <div
            v-if="
              (Array.isArray(order.payment) && order.payment.length) ||
              (typeof order.payment === 'object' &&
                Object.keys(order.payment).length)
            "
            class="text-center"
          >
            <div class="d-flex justify-content-between">
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />

                <a href="#" @click.prevent="handleDownload('do')">
                  Download Delivery Order
                </a>
              </div>
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />

                <a href="#" @click.prevent="handleDownload('inv')">
                  Download Invoice
                </a>
              </div>
              <div class="rounded border px-2 py-1">
                <feather-icon
                  style="color: red"
                  icon="DownloadIcon"
                  size="15"
                />

                <a href="#" @click.prevent="handleDownload('cover')">
                  Download Covernote
                </a>
              </div>
            </div>
          </div>
          <div v-else class="d-flex justify-content-center">
            <div>
              <a> No Receipts </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'

export default {
  components: {
    MyTable
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      src: '',
      order: {},
      quotes: {},
      columns: [
        {
          label: 'Order ID',
          field: 'id',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Category',
          field: 'order_type',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Delivery Type',
          field: 'delivery_type',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Shipment Type',
          field: 'shipment_type',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Pick Up Date',
          field: 'pickup_date',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Origin Address',
          field: 'pickup',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Destination Address',
          field: 'destination',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Freight ID',
          field: 'fleetId',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Quotation',
          field: 'quotation',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Documents',
          field: 'document',
          tdClass: 'text-center align-middle'
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center align-middle'
        }
      ],
      rows: []
    }
  },
  created() {
    this.fetchOrders()
  },
  methods: {
    fetchOrders() {
      this.$http
        .get(`orders?orders_for=fleet&filter_by=delivery_history`)
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows = [...response.data.data]
          } else {
            this.rows = []
          }
          this.isLoading = false
        })
    },
    goToOrderSummary(row) {
      this.$router.push({
        name: 'fleetforwardingdeliverysummary',
        params: {
          id: row
        }
      })
    },
    openModallist(id) {
      this.isLoading = true
      this.$http.get(`get_fleet_order/${id}`).then((response) => {
        if (response) {
          this.order = response.data.data
          this.$refs.modallist.show()
        }
      })
      this.isLoading = false
    },
    uploadFile(event) {
      this.$swal({
        title: 'Confirm Upload',
        text: 'Do you want to upload this file?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Yes',
        confirmButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-warning mr-2',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (!result.isConfirmed) {
          const formData = new FormData()
          formData.append('order_id', this.order.id)
          formData.append('documents[0]', event.target.files[0])

          this.$http
            .post(`upload_order_documents`, formData)
            .then((response) => {
              this.$swal('Success', 'File uploaded successfully!', 'success')
              this.openModallist(this.order.id)
            })
            .catch((error) => {
              this.$swal('Error', 'File upload failed!', 'error')
            })
        } else {
          this.$refs.fileInput.value = null
          this.$swal('Canceled', 'File upload canceled.', 'info')
        }
      })
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    isPDF(url) {
      return url.toLowerCase().endsWith('.pdf')
    },
    viewDocuments(row) {
      window.open(row, '_blank')
    }
  }
}
</script>
